import './college_acceptance_calculator.scss'

document.addEventListener('DOMContentLoaded', function() {
  const calcs = document.querySelectorAll('.college-acceptance-calc');

  calcs.forEach(calc => {
    let actScore = '';
    const calcForm = calc.querySelector('.college-acceptance-calc-form');
    const actSelectWrapper = calc.querySelector('.mdc-act-select');
    const actSelect = new mdc.select.MDCSelect(actSelectWrapper);
    actSelect.listen('MDCSelect:change', () => actScore = actSelect.value);

    const satSliderWrapper = calc.querySelector('.college-acceptance-calc--sat--slider');
    const satSlider = new mdc.slider.MDCSlider(satSliderWrapper);
    satSlider.listen('MDCSlider:input', () => {
      const parent = satSliderWrapper.closest('.college-acceptance-calc');
      parent.querySelector('.college-acceptance-calc--sat--number-wrapper--number').value = Math.floor(satSlider.value);
    });
      
    const gpa = calc.querySelector('.college-acceptance-calc--columns--column--gpa-wrapper--input');
    const invalidChars = ['-', '+', 'e'];
    gpa.addEventListener('keypress', (e) => {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    gpa.addEventListener('input', (e) => {
      const val = e.target.value;
      if (val.length === 1 && val > 4) {
        gpa.value = '4.0';
      } else if (val < 0) {
        gpa.value = '0.0';
      } else if (val.length === 2 && val !== '.') {
        let currentVal;
        if (+val[0] === 4) {
          currentVal = 4.0;
        } else {
          currentVal = val.split('').join('.');
        }
        gpa.value = currentVal;
      } else if (val.length > 2 && +val > 4 && val !== null) {
        gpa.value = '4.0';
      } else if (val.length > 3) {
        let currentVal = val.slice(0, 3);
        gpa.value = currentVal;
      }
    });

    calcForm.addEventListener('submit', function(e) {
      e.preventDefault();
      let params = '';
      let data = $(this).serializeArray().filter(param => param.name !== 'select-college');
      const selectedSchoolName = $('.select-college-dropdown-result-page').attr('data-checkschool');
      const selectedSchoolId = $('.select-college-dropdown-result-page').attr('data-checkschoolid');
      const actSelect = calc.querySelector('.mdc-act-select > .mdc-select__anchor');
      const gpaScore = calc.querySelector('.college-acceptance-calc--columns--column--gpa-wrapper--input');
      let act = actSelect.querySelector('.mdc-select__selected-text');

      selectedSchoolName ?
        data.push({ name: 'act', value: actScore }) :
        data.push({ name: 'act', value: actScore }, { name: 'page', value: 1 });

      Object.keys(data).forEach(key => {
        if (data[key].name == 'act' && act.innerHTML.length == 0) { return }
        params += `${data[key].name}=${data[key].value}&`
      });

      let checkPassed = true
      gpaScore.classList.remove('is-empty');

      if (!gpaScore.value) {
        gpaScore.classList.add('is-empty');
        checkPassed = false
      }
      if (checkPassed == false) {
        $('.college-acceptance-calc--submit').addClass( "shake" ).delay(900).queue(function() {
          $(this).removeClass('shake');
          $(this).dequeue();
        })
      } else {
        if (selectedSchoolName && !$(this).attr('data-current-user')) {
          window.location.href = `/users/chances_tool_registration?college_id=${selectedSchoolId}&${params}reg_source=calculator`;
        } else if (!selectedSchoolName && !$(this).attr('data-current-user')) {
          window.location.href = `/users/chances_tool_registration?${params}reg_source=calculator`;
        } else {
          selectedSchoolName ?
            window.location.href = `/college-acceptance-calculator/chance-of-getting-into-${selectedSchoolName}?${params}&chance=true` :
            window.location.href = `/college-acceptance-and-admissions-calculator?chance=true&${params}`;
        }
      }
      return false;
    });
  });
});
